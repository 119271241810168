import { graphql } from 'gatsby'
import defineHtmlAst from 'helpers/defineHtmlAst'
import indexAscendingSort from 'helpers/indexAscendingSort'
import { IBusinessesProps } from 'interfaces/PageInterfaces'
import React from 'react'
import { Helmet } from 'react-helmet'

import {
  BannerSection,
  DigitalLearningSection,
  ProvideSection,
  EnchanceSection,
  GrowthToolsSection,
  SimplePlatformSection,
  SupportTeamSection,
  SuadaHelpSection,
  TrustedCompaniesSection,
} from 'sections/Businesses'

const Businesses = ({ data }: IBusinessesProps) => {
  const {
    bannerText,
    missionText,
    humanElement,
    featuresArticles,
    trainingDelivery,
    digitalExperienceText,
    digitalExperienceArticles,
    growthToolsText,
    growthToolsArticles,
    singlePlatformText,
    singlePlatformArticles,
    supportYourTeamText,
    supportYourTeamArticles,
    suadaHelpArticles,
    partners,
  } = data

  trainingDelivery.innerArticleTexts = defineHtmlAst(
    trainingDelivery.innerArticles,
  )

  const enchanceArticles = [
    {
      title:
        digitalExperienceArticles.block5JourneyArticle1Title,
      description:
        digitalExperienceArticles
          .block5JourneyArticle1Description
          .block5JourneyArticle1Description,
    },
    {
      title:
        digitalExperienceArticles.block5JourneyArticle2Title,
      description:
        digitalExperienceArticles
          .block5JourneyArticle2Description
          .block5JourneyArticle2Description,
    },
    {
      title:
        digitalExperienceArticles.block5JourneyArticle3Title,
      description:
        digitalExperienceArticles
          .block5JourneyArticle3Description
          .block5JourneyArticle3Description,
    },
  ]

  const growthArticles = [
    {
      title: growthToolsArticles.block6ToolsArticle1Title,
      description:
        growthToolsArticles.block6ToolsArticle1Description
          .block6ToolsArticle1Description,
    },
    {
      title: growthToolsArticles.block6ToolsArticle2Title,
      description:
        growthToolsArticles.block6ToolsArticle2Description
          .block6ToolsArticle2Description,
    },
    {
      title: growthToolsArticles.block6ToolsArticle3Title,
      description:
        growthToolsArticles.block6ToolsArticle3Description
          .block6ToolsArticle3Description,
    },
    {
      title: growthToolsArticles.block6ToolsArticle4Title,
      description:
        growthToolsArticles.block6ToolsArticle4Description
          .block6ToolsArticle4Description,
    },
  ]

  const singleArticles = [
    {
      title:
        singlePlatformArticles.block7SinglePlatformArticle1Title,
      description:
        singlePlatformArticles
          .block7SinglePlatformArticle1Description
          .block7SinglePlatformArticle1Description,
    },
    {
      title:
        singlePlatformArticles.block7SinglePlatformArticle2Title,
      description:
        singlePlatformArticles
          .block7SinglePlatformArticle2Description
          .block7SinglePlatformArticle2Description,
    },
    {
      title:
        singlePlatformArticles.block7SinglePlatformArticle3Title,
      description:
        singlePlatformArticles
          .block7SinglePlatformArticle3Description
          .block7SinglePlatformArticle3Description,
    },
    {
      title:
        singlePlatformArticles.block7SinglePlatformArticle4Title,
      description:
        singlePlatformArticles
          .block7SinglePlatformArticle4Description
          .block7SinglePlatformArticle4Description,
    },
    {
      title:
        singlePlatformArticles.block7SinglePlatformArticle5Title,
      description:
        singlePlatformArticles
          .block7SinglePlatformArticle5Description
          .block7SinglePlatformArticle5Description,
    },
    {
      title:
        singlePlatformArticles.block7SinglePlatformArticle6Title,
      description:
        singlePlatformArticles
          .block7SinglePlatformArticle6Description
          .block7SinglePlatformArticle6Description,
    },
  ]

  const supportArticles = [
    {
      title:
        supportYourTeamArticles.block8CoachingArticle1Title,
      description:
        supportYourTeamArticles
          .block8CoachingArticle1Description
          .block8CoachingArticle1Description,
    },
    {
      title:
        supportYourTeamArticles.block8CoachingArticle2Title,
      description:
        supportYourTeamArticles
          .block8CoachingArticle2Description
          .block8CoachingArticle2Description,
    },
    {
      title:
        supportYourTeamArticles.block8CoachingArticle3Title,
      description:
        supportYourTeamArticles
          .block8CoachingArticle3Description
          .block8CoachingArticle3Description,
    },
    {
      title:
        supportYourTeamArticles.block8CoachingArticle4Title,
      description:
        supportYourTeamArticles
          .block8CoachingArticle4Description
          .block8CoachingArticle4Description,
    },
  ]
  const featuresArticlesSorted = featuresArticles.nodes
    .sort(indexAscendingSort)
    .slice(0, 3)

  return (
    <>
      <Helmet title="Businesses" defer={false} />
      <BannerSection {...bannerText} />
      <DigitalLearningSection
        {...missionText}
        article={humanElement}
      />
      <ProvideSection
        {...trainingDelivery}
        articles={featuresArticlesSorted}
      />
      <EnchanceSection
        {...digitalExperienceText}
        articles={enchanceArticles}
      />
      <GrowthToolsSection
        {...growthToolsText}
        articles={growthArticles}
      />
      <SimplePlatformSection
        {...singlePlatformText}
        articles={singleArticles}
      />
      <SupportTeamSection
        {...supportYourTeamText}
        articles={supportArticles}
      />
      <SuadaHelpSection
        articles={suadaHelpArticles.nodes}
      />
      <TrustedCompaniesSection partners={partners.nodes} />
    </>
  )
}

export default Businesses

export const query = graphql`
  query {
    bannerText: contentfulBusinessesPage {
      header: block1BannerHeader
      subHeader: block1BannerSubHeader
    }
    missionText: contentfulBusinessesPage {
      mission: block2MissionHeader
      description: block2MissionSubHeader
    }
    humanElement: contentfulBusinessesPage {
      header: block3DigitalWordTitle
      description: block3DigitalWordDescription {
        description: block3DigitalWordDescription
      }
    }
    trainingDelivery: contentfulBusinessesPage {
      header: block4TrainingDeliveryTitle
      description: block4TrainingDeliveryDescription {
        description: block4TrainingDeliveryDescription
      }
      innerArticles: block4TrainingDeliveryList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
    }
    featuresArticles: allContentfulHomePageFeaturesArticle {
      nodes {
        title
        description
        index
      }
    }
    digitalExperienceText: contentfulBusinessesPage {
      header: block5JourneyTitle
    }
    digitalExperienceArticles: contentfulBusinessesPage {
      block5JourneyArticle3Title
      block5JourneyArticle2Title
      block5JourneyArticle1Title
      block5JourneyArticle1Description {
        block5JourneyArticle1Description
      }
      block5JourneyArticle2Description {
        block5JourneyArticle2Description
      }
      block5JourneyArticle3Description {
        block5JourneyArticle3Description
      }
    }
    growthToolsText: contentfulBusinessesPage {
      header: block6ToolsTitle
    }
    growthToolsArticles: contentfulBusinessesPage {
      block6ToolsArticle4Title
      block6ToolsArticle4Description {
        block6ToolsArticle4Description
      }
      block6ToolsArticle3Title
      block6ToolsArticle3Description {
        block6ToolsArticle3Description
      }
      block6ToolsArticle2Title
      block6ToolsArticle2Description {
        block6ToolsArticle2Description
      }
      block6ToolsArticle1Title
      block6ToolsArticle1Description {
        block6ToolsArticle1Description
      }
    }
    singlePlatformText: contentfulBusinessesPage {
      header: block7SinglePlatformTitle
    }
    singlePlatformArticles: contentfulBusinessesPage {
      block7SinglePlatformArticle6Title
      block7SinglePlatformArticle6Description {
        block7SinglePlatformArticle6Description
      }
      block7SinglePlatformArticle5Title
      block7SinglePlatformArticle5Description {
        block7SinglePlatformArticle5Description
      }
      block7SinglePlatformArticle4Title
      block7SinglePlatformArticle4Description {
        block7SinglePlatformArticle4Description
      }
      block7SinglePlatformArticle3Title
      block7SinglePlatformArticle3Description {
        block7SinglePlatformArticle3Description
      }
      block7SinglePlatformArticle2Title
      block7SinglePlatformArticle2Description {
        block7SinglePlatformArticle2Description
      }
      block7SinglePlatformArticle1Title
      block7SinglePlatformArticle1Description {
        block7SinglePlatformArticle1Description
      }
    }
    supportYourTeamText: contentfulBusinessesPage {
      header: block8CoachingTitle
      subHeader: block8CoachingSubTitle
    }
    supportYourTeamArticles: contentfulBusinessesPage {
      block8CoachingArticle4Title
      block8CoachingArticle4Description {
        block8CoachingArticle4Description
      }
      block8CoachingArticle3Title
      block8CoachingArticle3Description {
        block8CoachingArticle3Description
      }
      block8CoachingArticle2Title
      block8CoachingArticle2Description {
        block8CoachingArticle2Description
      }
      block8CoachingArticle1Title
      block8CoachingArticle1Description {
        block8CoachingArticle1Description
      }
    }
    suadaHelpArticles: allContentfulHomePageSuadaHelpArticle {
      nodes {
        title
        description
      }
    }
    partners: allContentfulPartnerCompanies {
      nodes {
        logo {
          gatsbyImageData(width: 150, quality: 100)
        }
      }
    }
  }
`
